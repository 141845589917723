.header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tableWrapper {
  max-width: 100%;
  overflow-x: auto;
}

table {
  border-collapse: separate;
  border-spacing: 20px 0;
}

th {
  text-align: left;
  font-weight: 600;
}

table a {
  text-decoration: none;
  color: black;
}

table a img {
  width: 15px;
  margin-right: 5px;
}

table a:hover {
  text-decoration: underline;
}

@media screen and (min-width: 600px) {
  .tableContainer {
    padding: 0.5rem;
  }
}
