.container {
  width: 100%;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  gap: 15px;
}

.link {
  color: var(--subheader-color);
  font-weight: bold;
}

/** Canva **/
.canvas {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 75%;
  padding-bottom: 0;
  box-shadow: 0 2px 8px 0 rgba(63, 69, 81, 0.16);
  overflow: hidden;
  border-radius: 8px;
  will-change: transform;
}

.iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: none;
  padding: 0;
  margin: 0;
}
