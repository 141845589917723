.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-image: url('../../assets/rsbg.jpg');
  background-repeat: repeat-y;
  background-attachment: fixed;
  background-position: top center;
  background-size: auto;
  background-color: black;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 1rem 2rem;
  margin-bottom: 1rem;
  width: 100%;
}

.headerImg {
  width: 90%;
  max-width: 400px;
  margin-bottom: 1rem;
}
