h1 {
  color: var(--header-color);
  text-shadow: 1px 1px 1px #000000;
}

img {
  width: 100%;
}

.featuredContainer {
  display: flex;
  width: 100%;
  min-height: 325px;
}

.iframe {
  flex-grow: 1;
  border: none;
  margin: 0;
  padding: 0;
}

.socialsContainer {
  display: flex;
  gap: 1rem;
}

.socialsContainer a {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--subheader-color);
}

.socialsContainer img {
  width: 25px;
}
